.footer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  margin: 0 auto;
  margin-top: 8rem;
  padding-bottom: 1rem;
  img {
    position: absolute;
    top: 230rem;
    z-index: -3;
    height: 70rem;
    width: 100%;
  }
  h1 {
    color: #66bb6a;
    margin-top: 0;
    padding-top: 0;
  }
  .footer2 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 50%;
    .in-div {
      font-size: 16px;
      p {
        a {
          text-decoration: none;
          opacity: 0.5;
          color: #000000;
        }
      }
    }
    .in-div2 {
      width: 200px;
      height: 220px;
      font-size: 16px;
      background-color: #66bb6a;
      color: #ffffff;
      padding: 30px;
      border-radius: 5px;
      box-shadow: -1px 13px 20px rgb(223, 221, 221);
      button {
        padding: 10px 30px;
        color: #000000;
        border-radius: 5px;
        border: none;
      }
      p {
        line-height: 12px;
        a {
          text-decoration: none;
          opacity: 0.5;
          color: #ffffff;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .footer {
    flex-direction: column;
    .Zou {
      margin-left: 4rem;
    }
    .footer2 {
      flex-direction: column;
      width: 69%;
      margin: 0 auto;
      .in-div {
        margin-bottom: 1rem;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .footer {
    .in-div {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .margin {
        margin-right: -2rem;
      }
    }
    .footer2 {
      .in-div2 {
        width: 100%;
        width: 77vw;
        margin-left: -1rem;
      }
    }
  }
}

.section {
  display: flex;
  flex-direction: row;
  width: 70%;
  margin-top: 3rem;
  padding-bottom: 9rem;
  .hero {
    padding-top: 3rem;
    width: 50%;
    margin: 0 auto;
    .inputt {
      display: flex;
      flex-direction: row;
      margin-top: 3rem;
      width: 100%;
      .input_width {
        width: 75%;
        input {
          width: 96%;
          padding: 0.9rem 1rem;
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
          margin-right: 2rem;
          box-shadow: 0 9px 70px 0 #e8e8e8;
          background: #ffffff;
          border: 1px solid #ffffff;
        }
      }
    }
    h1 {
      line-height: 3.5rem;
      font-size: 46px;
    }
    p {
      line-height: 2rem;
      font-size: 16px;
    }
    button {
      width: 31%;
      padding: 0.9rem 0;
      color: #ffffff;
      background-color: #66bb6a;
      border: #66bb6a;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      cursor: pointer;
      filter: drop-shadow(0px 13px 20px rgba(118, 196, 125, 0.411204));
    }
  }
  .image {
    margin-top: -3rem;
    margin-left: 8rem;
  }
  .image2 {
    margin-top: 7rem;
    margin-left: 2rem;
  }
}
@media screen and (max-width: 1024px) {
  .section {
    width: 60%;
    text-align: left;
    .hero {
      margin-left: 5rem;
    }
  }
}
@media screen and (max-width: 768px) {
  .section {
    flex-direction: column;
    width: 70%;
    text-align: left;
    .hero {
      width: 100%;
    }
    .img {
      display: none;
    }
  }
}

// @media screen and (max-width: 600px) {
//   .section {
//     text-align: center;
//     width: 80%;
//     margin-left: -2.5rem;
//   }
// }
@media screen and (max-width: 600px) {
  .section {
    text-align: left;
    margin-top: 0;
    .hero {
      width: 100vw;
      text-align: center;
      margin-left: 0;
      .width {
        width: 90%;
        margin: 0 auto;
      }
      h1 {
        line-height: 36px;
        font-size: 1.8rem;
        margin-bottom: 1.3rem;
        font-weight: 700;
      }
      p {
        font-size: 0.9rem;
        line-height: 1.5;
        width: 85%;
        margin: 0 auto;
      }
      .inputt {
        flex-direction: column;
        margin-left: 1rem;
        width: 95%;

        .input_width {
          box-shadow: rgba(0, 0, 0, 0.1) 5px 5px 10px;
          width: 96%;
          input {
            width: 100%;
          }
        }
        button {
          width: 96%;
          margin-top: 0.75rem;
        }
      }
    }
  }
}

.bg-opportunities {
  width: 50%;
  margin: 0 auto;
  text-align: center;
  margin-top: 11rem;
  .mask {
    position: absolute;
    top: 30rem;
    left: -32rem;
  }
  .leaf {
    position: absolute;
    top: 55rem;
    left: -7rem;
  }
  h1 {
    font-size: 36px;
  }
  p {
    font-size: 18px;
    line-height: 32px;
  }
}

.bg-card {
  width: 70%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 auto;
  margin-top: 5rem;
  .card1 {
    width: 30%;
    line-height: 24px;
    font-size: 16px;
    font-weight: 400;
    background-color: #ffffff;
    padding: 30px;
    border-radius: 5px;
    box-shadow: -1px 13px 20px #f0f2f4;

    p {
      opacity: 0.5;
    }
  }
  .card3 {
    width: 30%;
    line-height: 24px;
    font-size: 16px;
    font-weight: 400;
    background-color: #ffffff;
    padding: 30px;
    border-radius: 5px;
    box-shadow: -1px 13px 20px #f0f2f4;

    p {
      opacity: 0.5;
    }
  }
  .card2 {
    width: 30%;
    line-height: 24px;
    font-size: 16px;
    font-weight: 400;
    color: #ffffff;
    background-color: #66bb6a;
    padding: 30px;
    border-radius: 5px;
    img {
      margin-bottom: 2rem;
    }
    p {
      opacity: 0.5;
    }
  }
}
@media screen and (max-width: 1024px) {
  .bg-opportunities {
    width: 100%;
    margin-top: -3rem;
    .mask {
      left: -34rem;
    }
    .leaf {
      top: 51rem;
      left: -11rem;
    }
  }
  .bg-card {
    width: 90%;
  }
}
@media screen and (max-width: 600px) {
  .bg-opportunities {
    h1 {
      font-size: 1.8rem;
    }
    p {
      font-size: 0.9rem;
      line-height: 1.5;
      width: 90%;
      margin: 0 auto;
    }
    .mask {
      left: -35rem;
    }
    .leaf {
      top: 49rem;
      left: -15rem;
    }
  }
  .bg-card {
    flex-direction: column;
    .card1 {
      width: 100%;
    }
    .card2 {
      width: 100%;
      margin-top: 1rem;
    }
    .card3 {
      width: 100%;
      margin-top: 1rem;
    }
  }
}

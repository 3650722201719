html,
body {
  margin: 0;
  padding: 0;
  font-family: "Chivo", sans-serif;
  overflow-x: hidden;
}

* {
  box-sizing: border-box;
}
